import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div id="main" className="alt">
        <section id="one">
            <div className="inner">
                <h1>Page couldn't be found</h1>
                <p>Please visit <Link to='/'>this page</Link></p>
            </div>
        </section>
    </div>
  </Layout>
)

export default NotFoundPage
